import Vue from 'vue'
import Item from './Item'
import {utils, writeFile} from 'xlsx'
import CustomField from './CustomFields/CustomField'
import definitions from '../../definitions'
import XlsxHelper from './XlsxHelper/XlsxHelper'
import CustomFields from './CustomFields/CustomFields'

/**
 * items with all reservations within a particular time span.
 * @param startDate: mandatory
 * @param endDate: mandatory
 */

export default class Items {
  constructor (params = {}, totalCount = 0) {
    this.items = []
    // count from api
    this.totalCount = totalCount

    // get items as api objects
    if (params.apiItems) {
      this.parseApiItems(params.apiItems)
    }
  }

  /**
   * Load all items for the home page
   * @return {Promise<void>}
   */
  async loadPublicItems () {
    /* global EventBus axios api i18n */
    EventBus.$emit('spinnerShow')

    try {
      let response = await axios.get(api + 'public/items/')
      this.parseApiItems(response.data)
    } catch (e) {
      Vue.notify({
        title: i18n.t('notify.itemsLoadFail'),
        type: 'error'
      })
    } finally {
      EventBus.$emit('spinnerHide')
    }
  }

  /**
   * loads all items with reservations from api within a particular time span
   * @param startDate
   * @param endDate
   * @returns {Promise<PromiseConstructor>}
   */
  async loadItems (startDate, endDate) {
    /* global EventBus axios api i18n */
    EventBus.$emit('spinnerShow')

    try {
      let response = await axios.get(api + 'items/reservation/overview/' + startDate / 1000 + '/' + endDate / 1000)

      if (response.status === 200) {
        this.parseApiItems(response.data)
      } else {
        Vue.notify({
          title: i18n.t('notify.itemsLoadFail'),
          text: response.status,
          type: 'error'
        })
      }
    } catch (e) {
      Vue.notify({
        title: i18n.t('notify.itemsLoadFail'),
        type: 'error'
      })
    } finally {
      EventBus.$emit('spinnerHide')
    }
  }

  /**
   * parse loaded api items into class instances
   * @param apiItems
   * @returns {PromiseConstructor}
   */
  parseApiItems (apiItems) {
    // reset api items
    this.items = []
    // iterate all items
    for (let i = 0; i < apiItems.length; i++) {
      const apiItem = apiItems[i]
      const itemToParse = apiItem.ConnectedSourceItem ? apiItem.ConnectedSourceItem : apiItem
      this.items.push(new Item(itemToParse))
    }
  }

  /**
   * fetch all custom fields for all items
   * @returns {Promise<PromiseConstructor>}
   */
  async fetchCustomFields () {
    if (this.items.length) {
      let customFieldsInstance = new CustomFields()

      // fetch custom fields
      await customFieldsInstance.fetch(this.items, definitions.customFields.types.item)
      let customFieldsArray = customFieldsInstance.getCustomFields()

      // iterate items
      for (let c = 0; c < this.items.length; c++) {
        let item = this.items[c]

        // reset item's custom fields
        item.resetCustomFields()

        // iterate custom fields
        for (let b = 0; b < customFieldsArray.length; b++) {
          let customField = customFieldsArray[b]
          if (item.getId() === customField.getId()) {
            item.getCustomFields().addCustomField(new CustomField(customField))
          }
        }
      }
    }
  }

  /**
   * return array with all item instances
   * @returns {Array}
   */
  getItems ({withTelOnly , withActive} = {withTelOnly: true, withActive: true}) {
    // filter
    if (!withTelOnly || !withActive) {
      return this.items.filter((item) => {
        if (!withTelOnly && item.telOnly) {
          return false
        }
        if (!withActive && !item.active) {
          return false
        }
        return true
      })
    }
    return this.items
  }

  /**
   * adds new item instance element to item array of the current class
   * @param itemInstance
   */
  addItemInstance (itemInstance) {
    // only add item if it's an instance of the Item class
    if (itemInstance instanceof Item) {
      this.items.push(itemInstance)
    }
  }

  /**
   * excel worksheet with item overview and sums
   * @returns {Promise<*>}
   */
  async getOverviewWorksheet () {
    let jsonWorksheet = []

    // iterate items
    for (let i = 0; i < this.items.length; i++) {
      let item = this.items[i]
      jsonWorksheet.push(await item.getJson())
    }

    let workSheet = utils.json_to_sheet(jsonWorksheet)

    // change header
    let xlsxHelper = new XlsxHelper(workSheet)
    let headerArray = [
      i18n.t('v13.internalNumber'),
      i18n.t('general.machine'),
      i18n.t('setAmount.startCounterShort'),
      i18n.t('setAmount.endCounterShort'),
      i18n.t('invoice.use'),
      '',
      i18n.t('invoice.totalExklTaxes'),
      '',
      i18n.t('v6.totalVat'),
      '',
      i18n.t('invoice.totalInklTaxes'),
      ''
    ]
    await xlsxHelper.setHeader(headerArray)

    // set auto width
    await xlsxHelper.setColumnWidthAuto()

    return xlsxHelper.getWorkSheet()
  }

  /**
   * Excel worksheet with all reservations in one list
   * @returns {Promise<void>}
   */
  async getAllWorksheet () {
    // init json worksheet
    let jsonWorkSheet = []

    // iterate all items
    for (let i = 0; i < this.items.length; i++) {
      let item = this.items[i]

      // concatenate arrays
      jsonWorkSheet = [...jsonWorkSheet, ...await item.getReservations().getJsonWorkSheet(item, true)]
    }

    // parse json array to excel work sheet
    let workSheet = await utils.json_to_sheet(jsonWorkSheet)

    // change header
    let xlsxHelper = new XlsxHelper(workSheet)
    let headerArray = [
      i18n.t('v13.internalNumber'),
      i18n.t('general.machine'),
      i18n.t('general.renter'),
      '',
      '',
      '',
      '',
      '',
      i18n.t('archiveExport.renterIsMember'),
      i18n.t('general.from'),
      '',
      i18n.t('general.to'),
      '',
      i18n.t('setAmount.startCounterShort'),
      i18n.t('setAmount.endCounterShort'),
      i18n.t('v2.counterCoefficient'),
      i18n.t('invoice.use'),
      '',
      i18n.t('invoice.pricePerUnit'),
      '',
      i18n.t('v6.minFeeShort'),
      '',
      i18n.t('general.total'),
      '',
      i18n.t('v6.cancelledThe'),
      i18n.t('v13.rejectedReservation'),
      i18n.t('v2.messageToReservation'),
      i18n.t('general.lessor'),
      '',
      '',
      '',
      '',
      ''
    ]

    await xlsxHelper.setHeader(headerArray)

    // set auto width
    await xlsxHelper.setColumnWidthAuto()

    return xlsxHelper.getWorkSheet()
  }

  // export items instance as excel
  async getWorkBook () {
    // A workbook is the name given to an Excel file
    let workBook = utils.book_new()

    // add the overview worksheet first
    await utils.book_append_sheet(workBook, await this.getOverviewWorksheet(), i18n.t('v6.overview'))

    // add the worksheet with all reservations second
    await utils.book_append_sheet(workBook, await this.getAllWorksheet(), i18n.t('v11.allReservations'))

    // iterate all items
    for (let i = 0; i < this.items.length; i++) {
      let item = this.items[i]
      let workSheet = await item.getReservations().getWorkSheet(item)

      // add work sheet to work book
      // fix. 07.05.2019: Save filename
      utils.book_append_sheet(workBook, workSheet, item.getSaveName(i))
    }

    // export Excel file
    writeFile(workBook, 'statistics_farmX.xlsx')
  }
}
